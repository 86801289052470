import { apiClient } from "@/services/HttpService";

// 登录
export function login(loginParam) {
  return apiClient.post("/login", loginParam);
}

// 注册
export function register(param) {
  return apiClient.post("/web/users/register", param);
}

// 忘记密码
export function unPass(form) {
  return apiClient.post("/web/users/un-pass", form);
}

// 发送验证码
export function sendSms(params) {
  return apiClient.post(
    `/sms/send/${params.type}?mobile=${params.mobile}&captcha=${params.captcha}`
  );
}
