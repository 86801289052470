<template>
  <section class="login-container">
    <div class="login-content-container">
      <div class="login-cover">
        <img src="../../assets/imgs/login/login_left.png" alt="登录背景图" />
      </div>
      <div class="login-form">
        <!--     注释登录切换     -->
        <!--        <div class="sms">-->
        <!--          <a v-if="!isSmsLogin" @click="switchLoginWay">验证码登录</a>-->
        <!--          <a v-else @click="switchLoginWay">账号密码登录</a>-->
        <!--        </div>-->
        <p class="login-title">电行世界</p>
        <div v-if="!isSmsLogin" class="input-container">
          <div class="input-comp">
            <a-input v-model="userName" placeholder="请输入账号">
              <a-icon slot="addonBefore" type="user" />
            </a-input>
          </div>
          <div class="input-comp">
            <a-input
              v-model="password"
              placeholder="请输入密码"
              type="password"
            >
              <a-icon slot="addonBefore" type="lock" />
            </a-input>
          </div>
          <div class="input-comp">
            <a-input
              v-model="captcha"
              placeholder="请输入图片验证码"
              :max-length="4"
              class="code-input"
            >
              <a-icon slot="addonBefore" type="safety-certificate" />
            </a-input>
            <img
              :src="captchaImgUrl"
              alt="验证码"
              class="captcha-img"
              @click="refreshCaptcha"
            />
          </div>
          <a-button
            type="primary"
            class="login-form-button"
            @click="handleSubmit"
          >
            登 录
          </a-button>
        </div>
        <div v-else class="input-container">
          <div class="input-comp">
            <a-input
              v-model="mobile"
              placeholder="请输入手机号"
              :max-length="11"
            >
              <a-icon slot="addonBefore" type="mobile" />
            </a-input>
          </div>
          <div class="input-comp">
            <a-input
              v-model="captcha"
              placeholder="请输入图片验证码"
              :max-length="4"
              class="code-input"
            >
              <a-icon slot="addonBefore" type="safety-certificate" />
            </a-input>
            <img
              :src="captchaImgUrl"
              alt="验证码"
              class="captcha-img"
              @click="refreshCaptcha"
            />
          </div>
          <div class="input-comp">
            <a-input
              v-model="sms"
              placeholder="请输入短信验证码"
              :max-length="6"
              class="code-input"
            >
              <a-icon slot="addonBefore" type="safety-certificate" />
            </a-input>
            <div>
              <button
                v-if="!isSend"
                type="button"
                class="btn-get"
                @click="sendCaptcha"
              >
                发送验证码
              </button>
              <button
                v-else
                type="button"
                :disabled="disableClick"
                :class="!disableClick ? 'btn-get' : 'btn-reget'"
                @click="sendCaptcha"
              >
                {{ captchaSendMsg }}
              </button>
            </div>
          </div>
          <a-button
            type="primary"
            class="login-form-button"
            @click="handleSubmit"
          >
            登 录
          </a-button>
        </div>
        <a-alert v-if="errMsg !== ''" type="error" :message="errMsg" banner />
      </div>
    </div>
  </section>
</template>

<script>
import { login, sendSms } from "@/services/LoginService";
import { baseURL } from "@/services/HttpService";

export default {
  data() {
    return {
      errMsg: "",
      userName: "",
      password: "",
      captcha: "",
      captchaImgUrl: `${baseURL}/captchaImg?tm=${new Date().getTime()}`,
      isSmsLogin: this.$route.query.isSmsLogin === "true",
      mobile: "",
      isSend: false,
      captchaSendMsg: "重新获取",
      disableClick: false,
      sms: ""
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    refreshCaptcha: function() {
      this.captchaImgUrl = `${baseURL}/captchaImg?tm=${new Date().getTime()}`;
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.verifyLogin()) {
        return;
      }
      const values = this.setLoginData();

      const that = this;
      login(values)
        .then(response => {
          if (response.data.code === "FAILED") {
            that.errMsg = response.data.errorMsg;
            that.refreshCaptcha();
          } else {
            const tokenInfo = response.data.data;
            localStorage.setItem("userId", tokenInfo.userId);
            localStorage.setItem("realName", tokenInfo.name);
            localStorage.setItem("accessToken", tokenInfo.token);
            localStorage.setItem("areaCode", tokenInfo.areaCode);
            localStorage.setItem("areaName", tokenInfo.areaName);
            localStorage.setItem("roles", JSON.stringify(tokenInfo.roles));
            localStorage.setItem("parentAreaCode", tokenInfo.parentAreaCode);
            if (
              tokenInfo.personnelTypeVOList &&
              tokenInfo.personnelTypeVOList.length > 0
            ) {
              const obj = tokenInfo.personnelTypeVOList[0];
              localStorage.setItem(
                "personnelType",
                JSON.stringify(tokenInfo.roles)
              );
              if (tokenInfo.roles.includes("FactoryAdmin")) {
                localStorage.setItem("factoryId", obj.corporationId);
              }
              localStorage.setItem("distributorId", obj.distributorId);
              localStorage.setItem("corporationId", obj.corporationId);
              localStorage.setItem("distributorType", obj.distributorType);
            }
            if (tokenInfo.menus && tokenInfo.menus.length > 0) {
              that.$store.state.selectedTopMenuId = tokenInfo.menus[0].menuId;
            }
            const openKeys = [];
            tokenInfo.menus.forEach(rootMenu => {
              if (rootMenu.subMenus) {
                rootMenu.subMenus.forEach(item => {
                  openKeys.push(item.menuId);
                });
              }
            });
            localStorage.setItem("openKeys", JSON.stringify(openKeys));
            that.$store.dispatch("setUserMenu", tokenInfo.menus);
            const menuList = [];
            const getMenuList = menus => {
              menus.forEach(item => {
                if (item.subMenus) {
                  getMenuList(item.subMenus);
                } else {
                  menuList.push(item.path);
                }
              });
            };
            getMenuList(tokenInfo.menus);
            that.$store.dispatch("setMenuList", menuList);
            if (tokenInfo.menus && tokenInfo.menus.length > 0) {
              const menu = tokenInfo.menus[0];
              // 如果获取到的第一个功能模块path为空，代表是父模块，无法进行跳转
              // 需要取第一个功能模块下第一个子模块进行跳转
              if (!menu.path && menu.subMenus.length > 0) {
                const subMenu = menu.subMenus[0];
                if (!subMenu.path && menu.subMenus.length > 0) {
                  that.$router.push({
                    name: subMenu.subMenus[0].path
                  });
                } else {
                  that.$router.push({
                    name: menu.subMenus[0].path
                  });
                }
              } else {
                that.$router.push({ name: menu.path });
              }
            } else {
              that.$message.info("暂无配置页");
            }
          }
        })
        .catch(error => {
          that.errMsg = error.responseText;
          that.refreshCaptcha();
        });
    },
    // 切换登录方式
    switchLoginWay() {
      this.isSmsLogin = !this.isSmsLogin;
    },
    // 发送验证码
    sendCaptcha() {
      if (this.mobile === "") {
        this.$message.info("请输入手机号");
        return;
      }
      if (this.mobile.length !== 11) {
        this.$message.info("手机号不足11位");
        return;
      }
      if (
        this.captcha === null ||
        this.captcha === "" ||
        this.captcha.length === 0
      ) {
        this.$message.info("请输入图片验证码");
        return;
      }
      this.isSend = true;
      this.disableClick = true;
      const params = {
        type: "LOGIN",
        mobile: this.mobile,
        captcha: this.captcha
      };
      sendSms(params).then(resp => {
        if (resp.data.code === "FAILED") {
          this.disableClick = false;
        } else {
          this.countDown();
        }
      });
    },
    // 倒计时
    countDown() {
      this.disableClick = true;
      const num = 60;
      this.captchaSendMsg = `重新获取(${num})`;
      for (let i = num; i >= 0; i--) {
        setTimeout(() => {
          this.captchaSendMsg = `重新获取(${i})`;
          if (i === 0) {
            this.disableClick = false;
            this.captchaSendMsg = "重新获取";
          }
        }, (num - i + 1) * 1000);
      }
    },
    // 登录校验
    verifyLogin() {
      let flag = true;
      if (!this.isSmsLogin) {
        if (this.userName === "") {
          this.errMsg = "请输入用户名";
          flag = false;
          return;
        }
        if (this.password === "") {
          this.errMsg = "请输入密码";
          flag = false;
          return;
        }
        if (this.captcha === "") {
          this.errMsg = "请输入图片验证码";
          flag = false;
          return;
        }
      } else {
        if (this.mobile === "") {
          this.errMsg = "请输入手机号";
          flag = false;
          return;
        }
        if (this.sms === "") {
          this.errMsg = "请输入短信验证码";
          flag = false;
          return;
        }
      }
      return flag;
    },
    // 设置登录传参
    setLoginData() {
      let data = {};
      if (this.isSmsLogin) {
        data = {
          mobile: this.mobile,
          captcha: this.sms,
          loginType: "mobileAndCaptcha"
        };
      } else {
        data = {
          userName: this.userName,
          password: this.password,
          captcha: this.captcha,
          loginType: "usernameAndPassword"
        };
      }
      return data;
    }
  }
};
</script>

<style scoped>
.input-comp {
  width: 60%;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.input-comp-icon {
  width: 16px;
}
.input-comp input {
  outline: none;
  border: none;
  margin-left: 6px;
  /*flex-grow: 1;*/
}
.login-container {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, #fefdff, #dce7ff);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 10vh;
  position: relative;
}
.login-content-container {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  min-width: 400px;
}
.login-cover {
  width: 32%;
  min-width: 200px;
}
.login-cover img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.login-form {
  width: 50%;
  min-width: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  align-items: center;
  position: relative;
}
.cover-img {
  width: 100%;
}
.login-title {
  color: #000;
  font-size: 22px;
}
.login-form-button {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 60%;
  height: 42px;
}
.footer {
  font-size: 14px;
  text-align: center;
  color: white;
  margin-top: 10vh;
}
.captcha-img {
  float: right;
  height: 37px;
  width: 80px;
  margin-right: 4px;
}
/deep/ .ant-input {
  height: 40px;
}

.code-input {
  width: 70%;
  margin-right: 5%;
}

.code-input-short {
  width: 75%;
  margin-right: 5%;
}

.code-input img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.sms {
  position: absolute;
  top: 20px;
  right: 20px;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-get {
  border: none;
  color: rgba(55, 105, 255, 1);
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}

.btn-reget {
  border: none;
  font-size: 14px;
  background-color: unset !important;
  cursor: unset;
}

.operation {
  flex: 1;
  display: flex;
  justify-content: center;
  min-width: 130px;
}
</style>
